@import '../../scss/variables';

body {
	padding: 0;
	margin: 0;
}
.round-img {
	width: 100px;
	height: 100px;
	margin-bottom: 20px;
	border: 1px solid blue;
	border-radius: 50%;
}
.card-pad {
	max-width: 19rem;
	padding: 20px 20px 20px 20px;
	border-radius: 20px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	.description-box{
		max-height: 15rem;
  		overflow: hidden;
 		text-overflow: ellipsis;
		
	}
}
.container__margin{
	margin-top: 110px !important;
}
.colors__text{
	color: $primary-1;
}
