@import "../../scss/variables";

.pricing_plan {
  padding-block: 2rem;
}
.pricing-list{
list-style: none;
}
.pricing-list li{
  font-size: 13px !important;
  margin-left: -30px !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.pricing-list li:hover{
  transform: translateY(-2px);
}
.pricingCard p{
  font-size: 14px;
}
.pricingCard h4{
  // font-size: 20px;
  text-transform: uppercase ;
  text-align: center;
  text-decoration: underline;
  padding-bottom: 10px;
}
.pricingCard .title-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main__pricing__card{
  padding: 20px 50px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}
.fa-price-icon{
  --size: 20px;
  background: #3f4696;
  color: #fff;
    border-radius: 50%;
  height: var(--size);
  width: var(--size);
  display: inline-flex;
  // align-items: center;
}

.fa-price-icon > * {
  margin: auto;
}
.single_price h5{
  background: $btn-color;
  color: white;
  text-align: center;
  padding: 10px 0px;
  border-radius: 10px;
  letter-spacing: 2px;
}
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}
div.scrollmenu .each-row {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.tabs{
  background-color: rgb(234, 234, 234);
  .nav-item{
   

      .nav-link{
        color: black;       
      }
      .active{
        background-color: white;
      }
  }
 
}
