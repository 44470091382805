@import "../../scss/variables.scss";

.auth-section {
 
  & > div {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .card {
    background-color: #40aeba6d;
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%);
    padding: 20px 65px;
    .query-text{
      width: 250px;
    }
    .para_welcome {
      color: $primary;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 4px !important;
    }

    // button
    .hover-up {
      background: $primary;
      border: transparent;
      border-radius: 4px;
      color: #fff;
      padding: 11px 11px;
      transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
      font-size: 15px;

      &:hover {
        transform: translateY(-2px);
        background-color: #3c65f5;
        color: #fff;
      }
    }

    .ank {
      font-family: Plus Jakarta Sans, sans-serif !important;
      text-decoration: none;
      font-size: 14px;
    }
  }
  input {
    
    &:focus{
      outline: none;
      border: 1px solid $primary-2;
      box-shadow: none;
    }
  }

  .img-size{
    width: 400px;
    height: 400px;
    
  }
}
