@import "../../scss/variables.scss";

.who_we_are {
  padding-block: 4rem;

  .who_card_box {
    padding-block: 0.5rem;
    .i{
      width: 100%;
    }

    .row > div {
      span {
        font-size: 24px;
        border-bottom: 2px solid $primary;
        font-weight: 700;
      }
      .sub-heading-who{
        font-size: 16px !important;
        font-weight: bolder;
      }
      p {
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1.5;
        font-weight: 400;
      }
    }
  }
}
