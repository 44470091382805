$white: #fff;
$black: #000;
$primary-2:#40adba;
$primary: #3f4796;
$primary-1: #16a7a7b3;
$font-regular : 14px;
$btn-color : #0d8065;
$did-you-know : #e4f3f5;

// Dimensions
$medium: 1200px;
$sm: 550px;
