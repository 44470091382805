@import "../../scss/variables";
.footer-section{
  background-color: aliceblue;
}
.footer {
  nav {
    display: flex;
    justify-content: center;
    gap: 6rem !important;
    padding: 3rem;
    background-color: aliceblue;

    h3 {
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.1em;
      line-height: 1.143;
      text-transform: uppercase;
      font-feature-settings: "ss07" on, "ss08" on, "ss02" on;
      color: #333234;
    }
    ul {
      list-style: none;
      font-size: 0.875rem !important;
      letter-spacing: 0.01em !important;
      line-height: 1.429 !important;
      font-feature-settings: "ss07" on, "ss08" on, "ss02" on !important;
      a {
        text-decoration: none;
        color: #4f4f4f;
      }
    }
  }
  .footer-card {
    padding: 1.5rem 1rem;
    background-color: #fff;
    color: #333234;
    border-radius: 1.2rem;
    text-align: center;
    h3 {
      color: #333234;
      font-size: 14px;
      letter-spacing: 0.01em;
      line-height: 1.25rem;
      margin-bottom: 4px;
    }
    a {
      text-decoration: none;
      color: $primary;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.1em;
      line-height: 1rem;
      text-transform: uppercase;
      margin: 4px 0;
    }
    p {
      font-size: 14px;
      margin: 4px;
      line-height: 1rem;
      letter-spacing: 0.01em;
      color: #333234;
    }
    div {
      padding: 0.2rem 1rem;
      border-radius: 0.2rem;
      background-color: $btn-color;
      color: #ffffff;
      margin: 24px 0 0;
      cursor: pointer;
      border-radius: 30px;
      padding: 10px 0;
    }
    @media screen and (width: 800px) {
      padding: 0.2rem 3rem !important;
    }
  }

  .footer-nav {
    padding: 0.75rem 4rem;
    background-color: $primary-2;
    font-size: 0.75rem !important;
    letter-spacing: 0.01em !important;
    line-height: 1.333 !important;
    font-feature-settings: "ss07" on, "ss08" on, "ss02" on !important;
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      color: white;
      li {
        padding: 0 8px;

        a {
          text-decoration: none;
          color: white;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    div {
      color: white;
    }
  }

  @media screen and (max-width: 800px) {
    .footer-nav {
      display: flex;
      flex-direction: column;
      justify-content: center;

      div {
        text-align: center;
      }
      ul {
        display: flex;
        justify-content: center;
        li {
          padding: 8px 8px;
        }
      }
    }
  }
}
.custom-footer {
  font-size: 13px;
ul{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  li{
    list-style-type: none;
     a{
      color: black;
      text-decoration: none;
      margin-left: 10px;
     }
  }
}  
}