@import "../../scss/variables";
.row {
  margin: 0;
}
.hero-section {
  // min-height: 100vh;
  background-size: 75px, auto;
  background: linear-gradient(0deg, #fff 30%, hsla(0, 0%, 100%, 0)),
    linear-gradient(-257deg, #40adba, #40adba 50%, #40adba);
  .content {
    padding-top: 12rem;
    color: $white;
    .allignment{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    p span svg path {
      color: #959fff;
    }
    .home__para {
      font-size: $font-regular;
      color: black;
      margin-bottom: 3px !important;
    }
    .title {
      font-weight: 700;
      font-size: 1.8rem;
      letter-spacing: -0.025em;
      line-height: 1.225;
      color: #000;
    }
.sub-heading-home{
  font-size: 1.2rem;
}
    .card {
      padding: 3rem 2rem;
      color: $black;
  

      .btn-primary {
        background-color: $btn-color;
        color: #fff;
        border-color: $btn-color;
      }
    }
  }
}

.panel-svg {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 2rem;
  background-color: $did-you-know;

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    li {
      flex: 1;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;

      svg {
        fill: #ddd;
      }
    }
  }
}

// how asaan apply work
.how_assanapply_work {
  padding: 4.5rem 0;
}

// do you know section
.do_you_know {
  background: $did-you-know;
  padding-block: 3rem;

  h6 {
    color: $primary;
  }
  p {
    font-size: 14px;
  }
}
.para_color {
  color: rgb(71, 71, 71);
}

.main-card-conatiner{
  position: relative;
  .query-text{
    font-size: 14px;
    font-weight: 100;
    color: rgb(108, 108, 108);
    width: 300px;
    margin: 0 auto; 
  }
}
.img-profile{
  position: absolute;
  top: -40px;
  left: 40%;
  z-index: 1;
}
.home-video{
  width: 100%;
}
.form-select{
  border: 2px solid rgb(209, 203, 203);
  
}
.form-select:focus{
  outline: none;
  box-shadow: none;
  border-color: #40adba;
}
.form-select:hover{
  cursor: pointer;
}


@media screen and (max-width: 1108px) {
  .hero-section .content .allignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .hero-section .content{
    padding-top: 8rem;
  }
  .main-card-conatiner{
    margin-top: 50px;
    .query-text{
      width: 100%;
    }
  }
}

@media screen and (max-width: 1109px) and (min-width: 991px)  {
  .custom-size{  
    width: 60%;
  }
  .main-card-conatiner{
    width: 60%;
  }
}