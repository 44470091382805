@import "../../scss/variables";

.ourmission {
  .coloured {
    color: $primary;
  }
  .sec-main {
    text-align: center;
    padding-block: 2rem;
  }
  .header-txt {
    .block {
      color: rgb(58, 55, 55);
      width: 948px;
    }
  }
  .main {
    .mt-4 {
    }
    .var-row {
      margin-top: 2rem !important;
    }
  }
  .container {
    border-style: none !important;
    max-width: 1021px;
  }
  .cards {
    border-style: none !important;
  }
  .header {
    .h1 {
      h1 {
        margin-left: 119px;
        width: 726px;
        color: rgba(24, 24, 24, 0.899);
        font-size: 2.1rem;
        letter-spacing: 0.5px;
        line-height: 1.225;
        font-feature-settings: "ss07" on, "ss08" on, "ss02" on;
      }
    }
  }
  .custom-style {
    .par1 {
      font-weight: 500;
      font-size: 17px;
    }
    .img {
      width: 100%;
    }
    .text-primary {
      --tw-text-opacity: 1 !important;
    }
    .variable {
      display: inline-block;
      border-bottom: 1.5mm ridge $primary;
      color: $primary;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.1em;
      line-height: 1.143;
      text-transform: uppercase;
      font-feature-settings: "ss07" on, "ss08" on, "ss02" on;
    }
    .pb-1 {
      padding-bottom: 0.25rem !important;
    }
    .border-primary {
      --tw-border-opacity: 1 !important;
      border-color: rgba(72, 29, 114, var(--tw-border-opacity)) !important;
    }
    .border-b-2 {
      border-bottom-width: 2px !important;
    }
    .whitespace-nowrap {
      white-space: nowrap !important;
    }
    .w-fit {
      width: -moz-fit-content !important;
      width: fit-content !important;
    }
    .sec-header {
      margin-bottom: 1rem !important;
    }
  }
}
.service-heading{
  font-size: 20px !important;
}
.service-title{
  font-size: 16px !important;
}