@import "../../scss/variables";

.about-us-section{
  display: flex;
  justify-content: center;
  align-items: center;
    min-height: 100vh;
    background-size: 75px, auto;
    background: linear-gradient(0deg,#fff 3%,hsla(0,0%,100%,0)),linear-gradient(-257deg,#40adba,#40adba 50%,#40adba);
}

.card-about{
  height: auto;
  background-color: #6cc0ca8f;
  border-radius: 10px;
  padding: 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.btn_about{
  background-color: $btn-color;
  color: #fff;
  padding: 5px 20px;
}
.btn_about:hover{
  background-color: $btn-color;
  color: #000;
}


.border-right-about{
  border-left: 2px solid $primary-2;
height: 100px;
}

.for-feedback{
  background-color: #e4f3f5;
  padding: 40px 0;
}
.feedback-text{
  font-size: 18px;
  font-weight: 300;
}
@media only screen and (max-width: 991px) and (min-width: 285px)  {
  .for-feedback{
    background-color: #e4f3f5;
    padding: 10px 0;
  }
}