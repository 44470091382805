.bg-custom {
    background-color: #EFEFEF;
}

.label {
    min-width: 200px;
    margin-left: 20px;
    font-size: small;
    font-family: sans-serif;
    color: #464646;
}
.label1 {
    min-width: 300px;
    margin-left: 20px;
    font-size: small;
    font-family: sans-serif;
    color: #464646;
}
.label {
    min-width: 200px;
    margin-left: 20px;
    font-size: small;
    font-family: sans-serif;
    color: #464646;
}

.span{
    min-width: 200px;
    margin-left: 20px;
    font-size: small;
    font-family: sans-serif;
    color: #464646;
}

option {
    font-size: 12px;
    font-family: sans-serif;
}

.form-title {
    font-family: Arial Helvetica,sans-serif;
    font-size: small;
    color: #00337D;
}

select.input-field option[disabled] {
    font-size: small;
    
}

.input-field {
    margin-left: 70px;
    flex-grow: 1;
    width: 100%;
    max-width: 300px;
    height: 19px;
    border: solid 1px #005B92;
    font-size: 12px;
    padding-inline: 3px;
}


.form-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Add some spacing between rows */
}
.line-bottom {
	border-bottom: 2px solid #005B92;
}
select {
    margin-left: 70px;
    flex-grow: 1;
    width: 100%;
    /* Make input fields expand to fill the available space */
    max-width: 300px;
    font-size: 12px;
}

.form-row.radio-buttons input[type="radio"] {
    flex-grow: 0;
    width: 13px;
}
#custom-select {
    width: 100px;
  }
.margin-left{
    margin-left: 180px;
}
.more-margin{
    margin-left: 200px;
}

label{
    font-size: small;
}
.button-center{
    display: flex;
    justify-content: end;
  }