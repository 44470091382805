@import "../../scss/variables";

.job1{
    font-size: 2em;
    font-weight: bold;
}
.job2{
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
}
.job3{
    font-size: 1.5em;
    font-weight: bold;
}
.job4{
    font-size: 16px;
    font-weight: bold;
}
.job5{
    font-size: 16px;
    font-weight: bold;
}
.jobcontainer{
    padding: 0 48px;
    border-radius: 3.5%;
}
