@import "../../scss/variables";

.second-contain {
    padding-block: 8rem;
}

.we-contain p {
    color: #344963;
    font-size: 18px;
    font-weight: 400;
    padding-right: 50px;
}

.form-contain {
    display: flex;
    justify-content: end;
    flex-direction: column;
    font-weight: 600;
    min-height: 460px;
    padding: 1em !important;
    box-shadow: 1px 14px 45px -20px #797d7d;
    border-radius: 10px 10px 10px 10px;
    background-color: #addce2a9;
}

.form-contain p {
    color: rgb(50, 50, 50);
    font-size: 17px;
    font-weight: 100;
}

.btn-send {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    width: 100%;
    margin-left: 3px;
    text-align: center;

}

.help-block.with-errors {
    color: #ff5050;
    margin-top: 5px;

}

.input-contact {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #34495e !important;
    border-style: solid !important;
    border-width: 1px 1px 1px 1px !important;
    border-color: #D0DDE6 !important;
    border-radius: 5px 5px 5px 5px !important;
    padding: 6px 16px !important;
    border: none;

    &:focus{
        outline: none;
        box-shadow: none;
    }
}

.label-contact {
    font-size: 14px;
    color: #121A21 !important;
    font-weight: 400;
}

.icon-contact {
    color: #3498db;
    font-size: 30px;
    font-weight: 400;
}

.contact-heading-3 {
    font-weight: 600;
    color: #121A21;
    text-align: left;
    font-size: 17px;
}

.icon-margin {
    margin-top: -5px !important;
}
.mail-link{
        text-decoration: none;
        color: black;
   
    }
