@import "../../scss/variables";
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  &.active {
    background-color: #fff;
  }

  .navbar {
    justify-content: flex-start;
    align-items: center;

    .desktop_navigation {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mobile_logo {
      display: none;
    }

    .navbar-brand img {
      width: 150px;
    }

    .navbar-nav {
      flex-direction: row;
    }

    .nav-link {
      color: #fff;
      margin-right: 1rem !important;
      transition: all 400ms ease;
      border-bottom: 1px solid transparent;

      &:hover {
        opacity: 0.8;
        border-color: $white;
      }

      &.active {
        border-color: $white;
      }
    }

    .auth-buttons {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .auth-button{
        margin-right: 10px;
      }
    }
  }

  .close_icons {
    display: none;
  }

  .humberger_icons {
    display: none;
  }

  @media screen and (max-width: $medium) {
    .humberger_icons {
      display: block;
      margin-left: auto;
    }

    .navbar {
      // display: none;

      .desktop_navigation {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        // padding-left: 2rem;
        background: #40adba !important;
        height: 100%;
        padding-block: 3rem;
        transform: translateY(-100%);
        transition: all 200ms ease;

        &.active {
          transform: translateY(0%);
        }

        .close_icons {
          position: absolute;
          right: 1rem;
          top: 1rem;
          display: block;
          font-size: 1.7rem;
        }
      }

      .navbar-brand {
        margin-left: -45px;
        &.mobile_logo {
          display: unset;
        }

        

        img {
          width: 250px;
          margin-top: 10px;
          margin-left: 20px;
          
        }
      }

      .navbar-nav {
        flex-direction: column;
        margin-bottom: 2rem;
        padding: 1rem;
        width: 100%;
        margin-left: 10px;

        .nav-link {
          font-size: 1.25rem !important;
          border-bottom: 2px solid rgb(220, 220, 220);
          padding-left: 0;
        }
      }
    }
    .navbar .auth-buttons{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .auth-button{
        margin-top: 15px;
        width: 90%;
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
  @media screen and (max-width: $sm) {
    .navbar .navbar-brand {
      margin-left: -29px;
    }
    .navbar {

      .auth-buttons {
        width: 100%;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.navbar > .container {
  display: block !important;
}
// .btn_color{
//   background-color: $primary-2;
// }