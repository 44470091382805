@import "../../scss/variables";
/* .menu {
    margin-left: 125px !important;
} */
/* .outline {
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.329);
    border-radius: 0px;
} */
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.bg {
	width: 100%;
	background: url(../../../public/images/bg.jpeg);
	background-repeat: no-repeat;
	background-size: cover;
}
.logo {
	width: 180px;
}
.backcolor {
	background-color: $primary-1;
	button{
		color: black;
		&:hover{
			background-color: #8adfdfb3;
			color: rgb(33, 33, 33);
		}
	}
}
.nav-margin {
	margin-top: -22px;
}
.input-padding {
	padding: 2rem;
}
.custom-width{
	width: 200px;
}
.span-padding {
	padding-bottom: 40px;
}
.active {
	border-radius: 0px !important;
}
.line-bottom {
	border-bottom: 1px solid black;
}
.short {
	width: 25%;
	display: inline;
}
.half {
	width: 30%;
	display: inline;
}
.duration {
	display: inline;
}
#gpa {
	width: 10%;
}
.file {
	width: 40%;
	display: inline;
}
.input-margin {
	margin-left: 15px;
}
.table {
	margin-top: 50px;
}
.menu {
	margin-left: 340px;
}
@media only screen and (min-width: 1988px) and (max-width: 2652px) {
	.menu {
		margin-left: 166px;
		margin-top: -20px;
	}
}
@media only screen and (min-width: 1404px) and (max-width: 1987px) {
	.menu {
		margin-left: 200px;
		margin-top: -20px;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1403px) {
	.menu {
		margin-left: 200px;
		margin-top: -30px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1198px) {
	.menu {
		margin-left: 143px;
		margin-top: -38px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 200px;
	}
	.hambur {
		// margin-left: 426px;
		// margin-top: -38px;
	}
	.short {
		width: 70%;
		display: block;
	}
	.half {
		width: 70%;
		display: block;
	}
	.file {
		width: 70%;
		display: block;
	}
	.span {
		font-size: 16px;
	}
	.opt {
		font-size: 15px;
		width: 250px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.duration {
		width: 20%;
		display: inline;
	}
}
@media only screen and (min-width: 560px) and (max-width: 767px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 150px;
	}
	.hambur {
		// margin-left: 325px;
	}
	.short {
		width: 100%;
		display: block;
	}
	.half {
		width: 100%;
		display: block;
	}
	.file {
		width: 100%;
		display: block;
	}
	.span {
		font-size: 14px;
	}
	.duration {
		width: 30%;
		display: inline;
	}
}
@media only screen and (min-width: 488px) and (max-width: 558px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 100px;
	}
	.hambur {
		// margin-left: 320px;
	}
	.short {
		width: 80%;
		display: block;
	}
	.half {
		width: 80%;
		display: block;
	}
	.file {
		width: 100%;
		display: block;
	}
	.span {
		font-size: 12px;
	}
	.duration {
		width: 40%;
		display: inline;
	}
}
@media only screen and (min-width: 428px) and (max-width: 487px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 100px;
	}
	.hambur {
		// margin-left: 295px;
	}
	.short {
		width: 80%;
		display: block;
	}
	.half {
		width: 80%;
		display: block;
	}
	.file {
		width: 100%;
		display: block;
	}
	.span {
		font-size: 10px;
	}
	.duration {
		width: 50%;
		display: inline;
	}
}
@media only screen and (min-width: 376px) and (max-width: 427px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 70px;
	}
	.hambur {
		// margin-left: 230px;
	}
	.short {
		width: 100%;
		display: block;
	}
	.half {
		width: 100%;
		display: block;
	}
	.file {
		width: 100%;
		display: block;
	}
	.span {
		font-size: 12px;
	}
	.duration {
		width: 60%;
		display: inline;
	}
}
@media only screen and (min-width: 200px) and (max-width: 375px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 40px;
	}
	.hambur {
		// margin-left: 240px;
	}
	.short {
		width: 160%;
		display: block;
	}
	.half {
		width: 160%;
		display: block;
	}
	.file {
		width: 160%;
		display: block;
	}
	.span {
		font-size: 5px;
	}
	.duration {
		width: 70%;
		display: inline;
	}
}
@media only screen and (min-width: 100px) and (max-width: 298px) {
	.bg {
		height: 70px;
	}
	.menu {
		margin-left: 40px;
	}
	.hambur {
		// margin-left: 240px;
	}
	.short {
		width: 100%;
		display: block;
	}
	.half {
		width: 100%;
		display: block;
	}
	.file {
		width: 100%;
		display: block;
	}
	.span {
		font-size: 8px;
	}
	.duration {
		width: 70%;
		display: inline;
	}
}
